import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    {
      staticClass: "fill-height justify-center pa-0",
      staticStyle: { "margin-top": "45px", height: "100vh" },
      attrs: { id: "register", tag: "section" },
    },
    [
      _c(
        VRow,
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCol,
            { attrs: { cols: "12" } },
            [
              _c(VImg, {
                staticClass: "mx-auto",
                staticStyle: { width: "47%", "max-width": "250px" },
                attrs: {
                  src: require("../../../../../../assets/dxa-white.png"),
                },
              }),
            ],
            1
          ),
          _c(VCol, { attrs: { cols: "12" } }, [
            _vm.$t("alpha_register_1_header") != "alpha_register_1_header"
              ? _c(
                  "h1",
                  {
                    staticClass: "text-center",
                    staticStyle: {
                      "font-weight": "bold",
                      color: "var(--primary)",
                      "font-size": "27px",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("alpha_register_1_header")) + " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c(VCol, { attrs: { cols: "12" } }, [
            _vm.$t("alpha_register_1_subheader") != "alpha_register_1_subheader"
              ? _c(
                  "h5",
                  {
                    staticClass: "text-center mx-5",
                    staticStyle: { "font-size": "18px", "line-height": "21px" },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("alpha_register_1_subheader")) + " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        VSlideYTransition,
        { attrs: { appear: "" } },
        [
          _c(
            VCard,
            {
              staticClass: "px-5 py-3 mb-0",
              staticStyle: {
                margin: "20px auto",
                display: "block",
                "min-height": "400px",
              },
              attrs: { color: "white", light: "", "max-width": "100%" },
            },
            [
              _c(
                "div",
                { staticClass: "pa-2" },
                [
                  _vm.emailSent
                    ? _c(
                        VRow,
                        {
                          staticClass: "my-3 mx-2",
                          attrs: { justify: "center", align: "center" },
                        },
                        [
                          _c("h2", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.$t("alpha_register_email_sent"))),
                          ]),
                        ]
                      )
                    : _c(
                        VForm,
                        {
                          ref: "form",
                          attrs: { "lazy-validation": _vm.lazy },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t("account_type")))]),
                          _c(
                            VRadioGroup,
                            {
                              attrs: { row: "" },
                              on: { change: _vm.juridical_type_changed },
                              model: {
                                value: _vm.user.JuridicalType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.user, "JuridicalType", $$v)
                                },
                                expression: "user.JuridicalType",
                              },
                            },
                            [
                              _c(
                                VRow,
                                {
                                  staticClass: "mx-1",
                                  attrs: { justify: "start" },
                                },
                                [
                                  _c(VRadio, {
                                    staticClass: "mr-8",
                                    attrs: { value: 0 },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "var(--dark)",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("fisical_person")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                  _c(VRadio, {
                                    attrs: { value: 1 },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "var(--dark)",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("legal_person"))
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.$t("name")))]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.Name,
                                expression: "user.Name",
                              },
                            ],
                            staticClass: "register-text-field",
                            class: { "invalid-field": !_vm.user.Name },
                            attrs: { type: "text" },
                            domProps: { value: _vm.user.Name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.user, "Name", $event.target.value)
                              },
                            },
                          }),
                          _vm.user.JuridicalType ==
                          _vm.JuridicalTypeEnum.NaturalPerson
                            ? _c("div", [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("last_name"))),
                                ]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.user.LastName,
                                      expression: "user.LastName",
                                    },
                                  ],
                                  staticClass: "register-text-field",
                                  class: {
                                    "invalid-field": !_vm.user.LastName,
                                  },
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.user.LastName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.user,
                                        "LastName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ])
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(_vm.$t("email")))]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.Email,
                                expression: "user.Email",
                              },
                            ],
                            staticClass: "register-text-field",
                            class: {
                              "invalid-field":
                                !_vm.user.Email ||
                                _vm.emailExists ||
                                _vm.emailFormatInvalid,
                            },
                            attrs: { type: "text" },
                            domProps: { value: _vm.user.Email },
                            on: {
                              change: function ($event) {
                                return _vm.check_email()
                              },
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.user, "Email", $event.target.value)
                              },
                            },
                          }),
                          _vm.validatingEmail
                            ? _c(
                                "p",
                                {
                                  staticClass: "mb-4 mt-n4 ml-2",
                                  staticStyle: {
                                    color: "var(--dark)",
                                    "font-size": "11px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("validating")) + " "
                                  ),
                                ]
                              )
                            : _vm.emailFormatInvalid
                            ? _c(
                                "p",
                                {
                                  staticClass: "mb-4 mt-n4 ml-2",
                                  staticStyle: {
                                    color: "red",
                                    "font-size": "11px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("format_invalid")) + " "
                                  ),
                                ]
                              )
                            : _vm.emailExists
                            ? _c(
                                "p",
                                {
                                  staticClass: "mb-4 mt-n4 ml-2",
                                  staticStyle: {
                                    color: "red",
                                    "font-size": "11px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("user_exists")) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            VRow,
                            {
                              staticClass: "mb-4",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "mx-0",
                                  staticStyle: { width: "90%", height: "52px" },
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.checkForm },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "1.3rem" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "alpha_register_create_portfolio"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _vm.alert
                    ? _c(
                        VAlert,
                        { staticClass: "ma-2", attrs: { type: "error" } },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.alert)) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }