import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=f71c8908&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "./Register.vue?vue&type=style&index=0&id=f71c8908&lang=scss&"
import style1 from "./Register.vue?vue&type=style&index=1&id=f71c8908&lang=css&"
import style2 from "./Register.vue?vue&type=style&index=2&id=f71c8908&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f71c8908')) {
      api.createRecord('f71c8908', component.options)
    } else {
      api.reload('f71c8908', component.options)
    }
    module.hot.accept("./Register.vue?vue&type=template&id=f71c8908&", function () {
      api.rerender('f71c8908', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/auth/register/alpha/user/Register.vue"
export default component.exports